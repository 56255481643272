#forecast-table td {
    border: none;
    color: black;
    height: 30px;
    padding: 0;
}

#forecast-table td.cell {
    padding: 0.3rem 5px 0.3rem 0.3rem;
    background: white;
    min-width: 75px;
}

#forecast-table td.cell.hoverMe {
    background: #D0DDDF;
}

#forecast-table td input
{
    padding: 0.3rem;
    width: 85px;
    border: none;
    background-color: #add8e6;
    text-align: right;
}

#forecast-table td input:focus{
    outline: none;
    background-color: #ddd;
    text-align: right;
}

#forecast-table td:focus-within
{
    outline: 2px solid #bbb;
    outline-offset: -2px;
    background-color: #ddd;
}

#forecast-table thead th {
    border-top: none;
    position: sticky;
    background: white;
    left: 160px;
    min-width: 75px;
    padding-right: 5px;
}


#forecast-table th {
    border-bottom: 1px solid gray;
}

#forecast-table th.row-header {
    min-width: 100px;
    position: sticky;
    left: -15px;
    background: white;
}

#forecast-table td.second-heading {
    min-width: 100px;
    white-space: nowrap;
    position: sticky;
    background: white;
    left: 85px;
    vertical-align: middle;
}
