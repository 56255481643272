.metric-menu-item {
    padding: 4px 8px;
    cursor: pointer;
    color: #505050;
}

.metric-menu-item:hover {
    padding: 4px 8px;
    cursor: pointer;
    background: #f1f1f1;
    color: black;
}
