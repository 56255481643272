#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(1),#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(2),#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(3),#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(4),#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(5),#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(6) {
    width: 145px;
    padding: 3px 0 3px 16px;
}

#reorders .byvendor .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(2) {
    width: 0 !important;
}

#reorders .byvendor .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(3) {
    width: 535px;
    padding-left: 40px !important;
    position: relative;
    top: 4px;
}

#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(3) {
    width: 145px;
}

#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(4) {
    width: 110px;
}

#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(6) {
    width: 158px;
}

.MuiFormControlLabel-root {
    margin: 0 !important;
}

#reorders .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(8) {
    padding-left: 20px !important;
    margin-right: 10px;
    color: #bbb;
}

#reorders span.MuiTypography-root {
    font-size: 13px;
    color: #bbb;
}


#reorders .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(10) {
    padding-left: 5px !important;
}

#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(10) {
    overflow: visible;
}

#reorders .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    margin-top: 0;
}

#reorders .MuiFormControl-root .MuiInputBase-input.MuiInput-input {
    color: #666;
}

#reorders .MuiFormControl-root .MuiFormLabel-root {
    top: -16px;
    color: #bbb;
}

#reorders .MuiInputLabel-formControl.MuiInputLabel-animated {
    margin-top: 0 !important;
}

#reorders .MuiTableCell-root.MuiTableCell-head {
    padding: 80px 0 0 15px;
}

#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(7), #reorders .MuiTableCell-root.MuiTableCell-head:nth-child(8), #reorders .MuiTableCell-root.MuiTableCell-head:nth-child(9),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(10),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(11),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(12),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(13),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(14),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(15),#reorders .MuiTableCell-root.MuiTableCell-head:nth-child(16) {
    padding: 80px 0 0 0;
}

#reorders .MuiTableCell-root.MuiTableCell-head:last-child {
    padding-right: 10px;
}

#reorders .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:last-child {
    padding-right: 10px;
    padding-left: 20px;
    position: relative;
    top: 4px;
}

#reorders .MuiToolbar-root.MuiToolbar-regular {
    padding-left: 16px;
}

#reorders .byproduct .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(7), #reorders .byproduct .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(9) {
    display: none;
}

.MuiMenuItem-root {
    padding-top: 0;
}

#reorders .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-body:nth-child(6) .MuiTypography-body1, #reorders .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-body:nth-child(8) .MuiTypography-body1, #reorders .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-body:nth-child(10) .MuiTypography-body1, #reorders .MuiTableBody-root .MuiTableRow-root:first-child .MuiTableCell-body:nth-child(2) .MuiTypography-body1 {
    color: #bbb;
}

#reorders .MuiBox-root {
    padding: 0;
}

#reorders .MuiAppBar-colorPrimary {
    color: black;
    background-color: white;
    box-shadow: none;
}

#reorders .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit:focus {
    outline: none;
}

#reorders .MuiPaper-rounded {
    border-radius: 5px;
    border: solid 1px #cbcbcb;
    padding: 0 5px;
    box-shadow: none;
}

#reorders .MuiTabs-indicator {
    background-color: rgb(16, 145, 133);
}

#reorders .byvendor .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body {
    width: 370px;
    color: #bbb;
}

#reorders .byvendor .MuiTableCell-root.MuiTableCell-head.MuiTableCell-paddingNone, #reorders .byvendor .MuiTableRow-root:first-child .MuiTableCell-root:nth-child(3), #reorders .byvendor .MuiTableBody-root .MuiTableRow-root.MuiTableRow-hover td.MuiTableCell-alignRight {
    display: none;
}

#reorders .byvendor .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-head:nth-child(3) {
    display: table-cell;
}

#reorders .byvendor .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-head {
    padding-bottom: 0;
}

#reorders .byproduct .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:nth-child(8) {
    padding-left: 0 !important;
    position: relative;
    top: 0;
}

#reorders .byvendor .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft:nth-child(3) {
    padding-left: 16px !important;
    position: relative;
    top: 0;
}

.MuiTableBody-root .MuiTableRow-root:hover {
    background-color: rgba(0, 0, 0, 0.04);
}

.MuiTableBody-root .MuiTableRow-root:first-child:hover {
    background-color: rgba(0, 0, 0, 0);
}

.MuiTable-root .MuiTableCell-root.MuiTableCell-body .MuiCheckbox-root .MuiSvgIcon-root {
    color: #2e787f;
}

#reorders .byvendor .MuiTableCell-root.MuiTableCell-head:last-child {
    display:none;
}

.MuiPaper-root .MuiTypography-displayBlock {
    display: block;
    font-size: 14px;
}

.MuiAutocomplete-popper li {
    font-size: 14px !important;
}

#reorders .byvendor .MuiTableRow-root:first-child .MuiTableCell-root.MuiTableCell-body:nth-child(5) {
    position: relative;
    top: 5px;
}

.norecords {
    padding-top: 40px;
}
