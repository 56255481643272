.custom-select ul::-webkit-scrollbar {
  width: 8px;
}
.custom-select ul::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.custom-select ul::-webkit-scrollbar-thumb {
  background: #bfbfbf;
}
.custom-select ul::-webkit-scrollbar-thumb:hover {
  background: #8d8d8d;
}
.custom-select {
  background: transparent;
}
