.list-group .list-group-item {
  background-color: inherit;
  border: none;
  border-radius: 0;
  color: rgba(41, 41, 41, 0.87);
  font-weight: 500;
  padding: 3px;
  overflow: hidden;
  white-space: nowrap;
  cursor: pointer;
}

li span {
  color: rgba(41, 41, 41, 0.87);
  display: inline-block;
  /* padding: 3px; */
}

li span:hover,
li .list-group-item:hover {
  border-radius: 8px;
  color: #15a58f;
  cursor: pointer;
}

.categories_menu ul li {
  overflow-x: hidden;
}
