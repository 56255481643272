.theme-button {
  background-color: #19d1dd;
  border: 0;
}

.theme-button:focus {
  background-color: #19d1dd;
}

.theme-button:hover {
  background-color: #1ab5c0;
}

.theme-button:disabled {
  background-color: #a2d1d4;
  border: 0;
}

.theme-button:disabled:hover {
  background-color: #a2d1d4;
  border: 0;
}

.theme-bg-color {
  background-color: #19d1dd;
}

.theme-bg-snd-color {
  background-color: rgb(69, 69, 69);
}

.theme-text-color {
  color: #19d1dd;
}

.theme-text-color.btn-disabled:hover {
  color: #19d1dd;
}

.theme-text-snd-color {
  color: rgb(119, 119, 119)
}

span.required-field {
  color: red
}

.action-edit, .action-view, .action-delete {
  padding: 4px 8px
}

.action-no-view {
  color: grey;
  background-color: inherit;
  border: none;
  padding: 4px 8px;
}

.action-no-view:hover {
  color: darkgray;
  background-color: inherit;
}

.action-no-view:disabled {
  color: darkgray;
  background-color: inherit;
}

.action-button-small {
  padding: 2px 4px
}

.card-shadow {
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.1)
}

.form-header {
  color: rgb(69, 69, 69);
  font-size: 18px;
  font-weight: 500;
  text-align: center;
}

.breadcrumb {
  background-color: inherit;
}

.btn-group>*, .btn-group>*:disabled {
  /*border: 1px solid #2cb5be!important*/
}

.big-dollar {
  color: #109185;
  display: block;
  font-weight: bolder;
  font-size: xx-large;
  line-height: 1
}

.reason-button:hover, .reason-button.active {
  background-color: #009785!important;
}

.filter-button {
  background-color: #f4f4f4;
  border: 1px solid #cecece;
  border-radius: 15px;
  color: #323232;
  font-size: 10px;
  padding: 3px 6px;
}

.filter-button:hover {
  background-color: lightgray;
  color: #323232;
}

.buttons-font {
  font-size: 10px !important;
}

.modal-content {
  border: none;
  border-radius: 16px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
}

#quarter-range-selector-modal .modal-content {
  border-radius: 8px;
}

.btn.disabled, .btn:disabled {
  cursor: not-allowed;
}
