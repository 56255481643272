.navmenu-icon {
  color: #00A9A7;
  font-size: 2em;
}
.navmenu-icon:hover {
  color: #4CC3C1;
}

.d-block {
  font-size: 80%;
  font-weight: 400;
  text-transform: uppercase;
  color: #565656;
}
.d-block:hover {
  color: #878787;
}
.navmenu-description {
  text-transform: uppercase;
}
