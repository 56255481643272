.list-group .list-group-item {
  background-color: inherit;
  border: none;
  border-radius: 0;
  color: rgba(41, 41, 41, 0.87);
  font-weight: 500;
  padding: 3px;
  white-space: nowrap;
}

li span {
  color: rgba(41, 41, 41, 0.87);
  display: inline-block;
  /* padding: 3px; */
}

li span:hover,
li .list-group-item:hover {
  /* background-color: rgb(231, 231, 231); */
  border-radius: 8px;
  color: #15a58f;
  cursor: pointer;
}

#options {
  background-color: #d8d8d8;
  font-size: 12px !important;
  padding: 0;
}

section.forecast-option-section {
  border-bottom: 1px solid white;
  padding: 15px;
}

section.forecast-option-section form .form-group .form-control,
.forecast-option-section form div select.custom-select {
  font-size: 12px !important;
  padding: 3px;
}

section.forecast-option-section button {
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  font-size: 12px !important;
}

.forecast-option-section form div input.form-control,
.forecast-option-section form div select.form-control,
.forecast-option-section form div select.custom-select {
  border-radius: 10px;
  padding: 0 10px;
  font-weight: 600;
  color: rgba(41, 41, 41, 0.87);
  letter-spacing: 1px;
  background: #fff
    url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24" fill="none" stroke="rgba(41, 41, 41, 0.87)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect><line x1="16" y1="2" x2="16" y2="6"></line><line x1="8" y1="2" x2="8" y2="6"></line><line x1="3" y1="10" x2="21" y2="10"></line></svg>')
    no-repeat left 0.75rem center;
}

.forecast-option-section form div select.form-control,
.forecast-option-section form div select.custom-select {
  background: #fff
    url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='grey' d='M2 4L0 0h4z'/></svg>")
    no-repeat right 0.75rem center/8px 10px;
}

.forecast-option-section form div label {
  color: #666;
  margin-bottom: 0;
  font-weight: 600;
}

.forecast-option-section h6 {
  font-size: 14px;
}

#forecast-table td {
  border: none;
  color: black;
}

#forecast-table td.tableInputTD input.tableInput:focus {
  outline-style: none;
  background-color: #ddd;
}

#forecast-table td.tableInputTD:focus-within {
  background-color: #ddd;
  outline-offset: -2px;
  outline: #bbb solid 2px;
}

#forecast-table thead th {
  border-top: none;
  min-width: 60px;
}

#forecast-table th {
  font-weight: 1000;
}

#forecast-table th.row-header {
  min-width: 100px;
  /* white-space: nowrap; */
}

#forecast-table td.second-heading {
  min-width: 100px;
  white-space: nowrap;
}

#forecast-table td.highlight-info {
  background-color: #c6f5f9;
  border-right: 1px solid white;
  border-top: 1px solid gray;
}

#forecast-table td.highlight-danger {
  background-color: #f2c5c3;
  border-right: 1px solid white;
}

#quarter-range-selector-table {
  margin-bottom: 0;
}

#quarter-range-selector-table td {
  /* border: 1px solid black; */
  border: none;
  font-weight: 500;
  padding: 7px 5px;
  width: 25%;
}

#quarter-range-selector-table tr:nth-child(2n + 1) td {
  color: #737373;
  font-size: 12px;
  line-height: 14px;
  padding-left: 18px;
}

#quarter-range-selector-table tr:nth-child(2n) td {
  color: #3c3c3c;
  font-size: 14px;
  padding: 10px 7px;
  text-align: center;
}

#quarter-range-selector-table tr:nth-child(2n) td:hover {
  cursor: pointer;
}

#quarter-range-selector-table tr td.highlight {
  background-color: #12e4d4;
  color: white;
}

#quarter-range-selector-table td.highlight-hover {
  background-color: #97ccc8;
}

#quarter-range-selector-table td.highlight-start {
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
}

#quarter-range-selector-table td.highlight-end {
  border-top-right-radius: 22px;
  border-bottom-right-radius: 22px;
}

.tableInput {
  width: 100%;
  border: none;
  background-color: lightblue;
  text-align: right;
}

.tableInput:focus {
  outline: none;
}

.tableInputTD {
  background-color: lightblue;
}


.header {
  margin-bottom: 25px;
}

.title {
  color: rgb(69, 69, 69);
  text-transform: uppercase;
}

.sub-title {
  color: #454545;
}

.widget-container {
  border: 1px solid lightgray;
  border-radius: 8px;
  padding: 1%;
  margin-bottom: 25px;
}

