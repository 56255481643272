/* Style table filter so it shows up above the data */

.top-movers-wrap .MuiTableBody-root .MuiTableRow-root:first-child {
    position: absolute;
    height: 50px !important;
    top: 0;
    z-index: 99;
    margin-left: 0;
}

.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body {
    padding-top: 16px;
}

.MuiTableRow-root .MuiTableCell-root.MuiTableCell-body {
    padding: 0;
    border:none;
}

#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body.MuiTableCell-alignLeft {
    padding: 16px 16px 10px 16px;
}

#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(1),#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(2),#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(3) {
    width: 200px;
    padding: 3px 10px 3px 16px;
}

#topmovers .MuiFormControl-root .MuiInputBase-root.MuiInput-root {
    margin-top: 0;
}

#topmovers .MuiFormControl-root .MuiInputBase-input.MuiInput-input {
    color: #666;
}

#topmovers .MuiFormControl-root .MuiFormLabel-root {
    top: -16px;
    color: #bbb;
}

#topmovers .MuiInputLabel-formControl.MuiInputLabel-animated {
    margin-top: 0 !important;
}

#topmovers .MuiTableCell-root.MuiTableCell-head {
    padding: 40px 0 0 15px;
}

#topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(4), #topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(5),#topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(6),#topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(7),#topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(8),#topmovers .MuiTableCell-root.MuiTableCell-head:nth-child(9) {
    padding: 40px 0 0 0;
}

#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(4), #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(5), #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(6), #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(7), #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(8), #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:nth-child(9) {
    padding: 1px 0 1px 0;
}

#topmovers .MuiToolbar-root.MuiToolbar-regular {
    padding-left: 16px;
}


#topmovers .MuiTableCell-root.MuiTableCell-head:last-child, #topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body:last-child {
    padding-right: 10px;
}

#topmovers .MuiTableRow-root .MuiTableCell-root.MuiTableCell-body {
    white-space: nowrap;
}

.select-product-type-btn {
    background-color: #109185;
    color: #fff;
    border: none;
    padding: 5px 10px;
}

.select-product-type-btn:focus, .select-product-type-btn:active {
    outline: none;
}
