.numeric-format-input {
  border: none;
  text-align: right;
  width: 100%;
  color: #4da1aa;
  font-weight: bold;
}

.numeric-format-input:focus-visible {
  outline: none;
}

.numeric-format-input.lcoation {
  font-size: 20px;
}

.numeric-format-input.center {
  text-align: center;
}

.numeric-format-input.right {
  text-align: right;
}

.numeric-format-input.left {
  text-align: left;
}
