.hide-input-border {
    border: none;
    background-color: transparent;
    padding-left: 0;
    padding-right: 0;
    outline: none;
    width: 100%;
}

.dollar-sign {
    position: absolute;
    left:15px;
    top:15px;
}

.indent-input {
    text-indent: 2px;
}

.payment-after {
    width: 130px;
}

.rdt_TableHeadRow .rdt_TableCol {
    min-width: 10px;
    overflow: hidden;
    padding-left: 5px;
    padding-right: 5px;
}

.rdt_TableRow .rdt_TableCell {
    min-width: 10px;
}

.rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable div {
    line-height: 17px;
    white-space:initial;
    text-align: right;
}

#column-1 {
    padding-left: 10px;
}

div[role='gridcell'] {
    padding: 0 5px;
}
