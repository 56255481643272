
#quarter-range-selector-table td {
    border: none;
    font-weight: 500;
    padding: 7px 5px;
    width: 25%;
    -webkit-user-select: none; /* Safari */
    -moz-user-select: none; /* Firefox */
    -ms-user-select: none; /* IE10+/Edge */
    user-select: none; /* Standard */
}

#quarter-range-selector-table tr:nth-child(2n+1) td {
    color: #737373;
    font-size: 12px;
    line-height: 14px;
    padding-left: 18px;
}

#quarter-range-selector-table tr:nth-child(2n) td {
    color: #3C3C3C;
    font-size: 14px;
    text-align: center;
}

#quarter-range-selector-table tr:nth-child(2n) td:hover {
    cursor: pointer;
}

#quarter-range-selector-table tr td.highlight {
    background-color: #12E4D4;
    color: white;
}

#quarter-range-selector-table td.highlight-hover {
    background-color: #97ccc8;
}

#quarter-range-selector-table td.highlight-start {
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
}

#quarter-range-selector-table td.highlight-end {
    border-top-right-radius: 22px;
    border-bottom-right-radius: 22px;
}
