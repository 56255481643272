#column-7 {
    padding-right: 10px;
}

.text-grey {
    color: #454545;
}

.fw-100 {
    font-weight: 100;
}

.px-30 {
    padding-left: 50px;
    padding-right: 50px;
}

.graph-text-paragraph {
    color: #444;
    width: 80%;
    margin-bottom: 40px;
}

.dashboard-page .card label.MuiFormControlLabel-root {
    justify-content: flex-end;
}

.dashboard-page .MuiTabs-scroller.MuiTabs-fixed {
    border-radius: 5px;
    max-height: 28px;
}

.dashboard-page .MuiTabs-flexContainer .MuiButtonBase-root {
    background-color: #fff;
    color: #555;
    border: 1px solid #555;
    padding: 0;
    min-height: 28px;
}

.dashboard-page .MuiTabs-flexContainer .MuiButtonBase-root:first-child {
    border-radius: 5px 0 0 5px;
}

.dashboard-page .MuiTabs-flexContainer .MuiButtonBase-root:nth-child(2) {
    border-radius: 0 5px 5px 0;
}

.dashboard-page .MuiTabs-flexContainer .MuiButtonBase-root.Mui-selected {
    border: 1px solid #109185;
    color: #109185;
    background-color: rgba(16, 145, 133, .3);
}

.dashboard-page .MuiTab-root {
    min-width: 100px;
} 
